import React, { useState } from 'react';
import { CardContent, CardHeader } from '@mui/material';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import BricksCreativeInput from 'components/bricks/components/shared/components/creative-input/components';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import Translation from 'components/data/Translation';
import { MODEL_ASSET_VALIDATION_RESULTS, MODEL_CREATIVES, MODEL_TITLE } from 'components/bricks/constants';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { BrickSetup } from 'components/bricks/types/brick.type';
import ComponentStore from 'components/data/ComponentStore';
import Card from 'components/ui-components-v2/Card';
import useBrick from '../../../../hooks/useBrick';
import CreativeActions from './creative-actions';

import './styles/creative.scss';

const Creative = () => {
    const [showAssetGalleryDialog, setShowAssetGalleryDialog] = useState(false);
    const { brick } = useBrick();

    if (!brick) return null;

    const activeItemId = brick.id;

    /** Remove the asset from the brick. */
    const handleDeleteAsset = () => {
        if (!activeItemId) return;

        const brickSetup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick.subType, 'setup');
        const brickTitle = brickSetup?.title ?? '';
        const brickPrefix = BrickHelpers.getBrickPrefix(activeItemId);

        ComponentStore.setMultiModels('Bricks', [
            [`${brickPrefix}.${MODEL_CREATIVES}`, undefined],
            [`${brickPrefix}.${MODEL_ASSET_VALIDATION_RESULTS}`, undefined],
            [`${brickPrefix}.${MODEL_TITLE}`, brickTitle]
        ]);

        SnackbarUtils.success(Translation.get('assetRemoved', 'bricks'));
    };

    return (
        <TabWrapper classes={{ root: 'single-asset-tab-wrapper' }}>
            <Card className="single-asset-tab-wrapper__card">
                <CardHeader
                    title={Translation.get('labels.creative')}
                    action={
                        <CreativeActions
                            showAssetGalleryDialog={showAssetGalleryDialog}
                            onSetShowAssetGalleryDialog={setShowAssetGalleryDialog}
                            onHandleDeleteAsset={handleDeleteAsset}
                        />
                    }
                    classes={{ root: 'single-asset-tab-wrapper__card-header' }}
                />
                <CardContent className="single-asset-tab-wrapper__card-content">
                    <BricksCreativeInput
                        key={`bricks-creative-input-${brick.id}`}
                        brick={brick}
                        showAssetGalleryDialog={showAssetGalleryDialog}
                        showComments
                        setShowAssetGalleryDialog={setShowAssetGalleryDialog}
                    />
                </CardContent>
            </Card>
        </TabWrapper>
    );
};

export default Creative;
