import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'feed_custom',
    title: 'Feed | Custom setup',
    channel: 'feed',
    description: 'Setup a custom feed and connect one or more items to it.',
    addBrickText: 'Add custom feed',
    availableParentBricks: [],
    config: {
        tableWidget: 'feed',
        masterBrick: {
            masterSection: {
                action: 'add',
                title: 'Master',
                icon: 'settings_input_component',
                buttonIcon: 'add_outlined',
                buttonLabel: 'Add children',
                color: 'primary'
            },
            childrenSection: {
                action: 'output',
                title: 'Output',
                icon: 'auto_awesome',
                buttonIcon: 'refresh_outlined',
                buttonLabel: 'Generate output',
                color: 'success'
            }
        }
    },
    availableChildBricks: [
        'x_campaign',
        'x_adset',
        'x_ad',
        'meta_campaign',
        'meta_adset',
        'meta_ad',
        'creative_image',
        'creative_displayad',
        'creative_all',
        'creative_video',
        'creative_pdf',
        'youtube_post',
        'snapchat_campaign',
        'snapchat_ad',
        'snapchat_adsquad',
        'group'
    ],
    outputAction: 'publish',
    defaultTab: 'feed',
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'feed',
            title: 'Feed',
            type: 'component',
            component: 'feed'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Export',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
