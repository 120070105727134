import React, { useState } from 'react';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import RadioList from 'components/input/RadioList';
import { Preset } from 'components/bricks/types/preset';
import { MODEL_ASSET_VALIDATION_RESULTS, MODEL_PRESETS, MODEL_UPLOAD_RESTRICTION_TYPE } from 'components/bricks/constants';
import DefaultBriefingContent from 'components/bricks/components/shared/components/briefing-content/components';
import { BrickData } from 'components/bricks/types/brick.type';
import AssetRestrictionsHelper from 'components/bricks/helpers/asset-restriction.helpers';
import { getCreativeFromBrick } from 'components/bricks/components/shared/components/creative-input/helpers/asset.helpers';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import { BriefingProps } from 'components/bricks/components/shared/components/briefing-content/components';
import Accordion from 'components/bricks/components/shared/components/accordion';
import PresetSelector from 'components/input/PresetSelector';
import useBrick from '../../../../hooks/useBrick';

import './styles/briefing.scss';

const Briefing = ({ showTitle, isTemporaryBrick }: BriefingProps) => {
    const { brick } = useBrick(undefined, isTemporaryBrick);

    const [selectedPresets, setSelectedPresets] = useState<Preset[]>(brick?.data?.presets || []);

    if (!brick) return null;

    const onUploadRestrictionChange = (value: BrickData['uploadRestrictionType']) => {
        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_UPLOAD_RESTRICTION_TYPE, value); // Update the upload restriction type.

        // If the asset is free then clear the validation results.
        if (value === 'free') {
            BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_ASSET_VALIDATION_RESULTS, undefined); // Clear the validation results.
            return;
        }

        // If the asset has selected presets then run the asset validation.
        if (selectedPresets.length > 0) {
            handlePresetValidation(selectedPresets); // Run validation on the asset
        }
    };

    /**
     * Run validation on the asset based on the selected presets.
     * @param selectedPresets The selected presets.
     */
    const handlePresetValidation = (selectedPresets: Preset[]) => {
        const creative = getCreativeFromBrick(brick);

        // If an creative exists in the brick we have to re-run the validation call
        if (creative && selectedPresets.length > 0) {
            const restrictions = selectedPresets[0].restrictions;
            const recommendations = selectedPresets[0].recommendations;
            const restrictionsFeedback = AssetRestrictionsHelper.validateAssetMetadataByRestrictions(creative.data, restrictions, recommendations); // Run the validation on the asset

            BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_ASSET_VALIDATION_RESULTS, restrictionsFeedback); // Save the validation results to the model
            return;
        }

        // If there is a creative but no presets are selected, clear the validation results. This will make sure that the validation results are cleared when the user removes the presets.
        if (creative && selectedPresets.length === 0) {
            BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_ASSET_VALIDATION_RESULTS, undefined); // Clear the validation results.
        }
    };

    const onPresetChange = (value: Preset[]) => {
        setSelectedPresets(value);
        handlePresetValidation(value); // Run validation on the asset

        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_PRESETS, value);
    };

    // If the parent has an upload restriction type we use that value
    const uploadRestrictionType = brick.data?.uploadRestrictionType || 'free';

    return (
        <TabWrapper classes={{ root: 'single-asset-briefing' }}>
            <DefaultBriefingContent noMargin showTitle={showTitle} isTemporaryBrick={isTemporaryBrick} />

            <Accordion title="Asset restrictions">
                <div>
                    <RadioList
                        value={uploadRestrictionType}
                        options={[
                            { key: 'free', value: 'Free upload' },
                            { key: 'restricted', value: 'Limited & restricted upload' }
                        ]}
                        onMutation={onUploadRestrictionChange}
                    />
                    {uploadRestrictionType === 'restricted' && <PresetSelector value={selectedPresets} onMutation={onPresetChange} />}
                </div>
            </Accordion>
        </TabWrapper>
    );
};

export default Briefing;
