import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Feed name',
                identifier: 'feed_custom-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            },
            {
                type: 'addFeedSelector',
                model: 'dataset',
                identifier: 'feed_custom-add_action',
                label: 'Feed Selector'
            }
        ]
    },
    {
        title: 'Publish output',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'radioList',
                model: 'publishType',
                label: 'Publish behaviour',
                identifier: 'feed_custom-publish-type',
                options: [
                    {
                        key: 'manual',
                        value: 'Manual publish'
                    },
                    {
                        key: 'auto',
                        value: 'Auto publish'
                    }
                ]
            },
            {
                type: 'alert',
                message: 'Manual publish: You need to publish items in the publish tab manually',
                condition: 'additionalVars.brick.data.settings.publishType === "manual"',
                alertType: 'info'
            },
            {
                type: 'alert',
                message: 'Auto publish: All items will be published after feed update',
                condition: 'additionalVars.brick.data.settings.publishType === "auto"',
                alertType: 'info'
            }
        ]
    }
];

export default settings;
