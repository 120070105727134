import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'peachconnect_ad',
    title: 'PeachConnect Ad',
    channel: 'convergedtv',
    platform: 'peachconnect',
    description: 'Create a Peach ad',
    addBrickText: 'Add ad',
    availableParentBricks: ['peachconnect_campaign'],
    outputAction: 'all',
    availableChildBricks: [],
    additionalVars: ['multiSocial_adset', 'feed_custom', 'peachconnect_campaign'],
    defaultTab: 'adSetup',
    config: {
        general: {
            publish: {
                previewType: 'social'
            }
        }
    },
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'creatives',
            title: 'Creative',
            type: 'component',
            component: 'creatives'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output',
            rights: ['brickManagement', 'brickPublish']
        }
    ]
};

export default setup;
