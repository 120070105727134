import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Creative',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'creativeSelector',
                label: 'Select your template',
                model: 'creatives',
                identifier: 'template_creative',
                templateTypes: [
                    'displayAdDesigned',
                    'dynamicImageDesigned',
                    'dynamicVideoDesigned',
                    'displayAd',
                    'dynamicImage',
                    'dynamicVideo',
                    'dynamicPDF',
                    'dynamicPDFDesigned',
                    'dynamicAfterEffects',
                    'dynamicInDesign'
                ],
                multiple: false
            }
        ]
    }
];

export default settings;
