import React from 'react';
import get from 'lodash/get';
import { MODEL_CREATIVES } from 'components/bricks/constants';
import { CreativeV2CustomUpload } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import IconButton from 'components/ui-components-v2/IconButton';
import User from 'components/data/User';
import Icon from 'components/ui-components-v2/Icon';
import useBrick from 'components/bricks/hooks/useBrick';
import { useCommentsContext } from 'components/social/context/comments.context';

interface Props {
    showAssetGalleryDialog: boolean;
    onSetShowAssetGalleryDialog: (showAssetGalleryDialog: boolean) => void;
    onHandleDeleteAsset: () => void;
}

const CreativeActions = ({ showAssetGalleryDialog, onSetShowAssetGalleryDialog, onHandleDeleteAsset }: Props) => {
    const { brick } = useBrick();
    const { commentsAddMarkersOverlayOpen, setCommentsAddMarkersOverlayOpen } = useCommentsContext();
    if (!User.hasRight('brickManagement')) return null;

    const creatives: CreativeV2CustomUpload[] | undefined = get(brick, MODEL_CREATIVES);
    const fileType = creatives?.[0].data.fileType;

    return (
        <div className="single-asset-creative">
            {fileType && ['video', 'audio', 'image'].includes(fileType) && (
                <IconButton
                    onClick={() => setCommentsAddMarkersOverlayOpen(!commentsAddMarkersOverlayOpen)}
                    size="small"
                    color={commentsAddMarkersOverlayOpen ? 'primary' : 'inherit'}>
                    <Icon>ads_click_outline</Icon>
                </IconButton>
            )}
            <IconButton onClick={() => onSetShowAssetGalleryDialog(!showAssetGalleryDialog)} size="small">
                {creatives && creatives.length > 0 ? <Icon>edit</Icon> : <Icon>add_photo_alternate</Icon>}
            </IconButton>
            {creatives && (
                <IconButton onClick={() => onHandleDeleteAsset()} size="small">
                    <Icon>delete_outline</Icon>
                </IconButton>
            )}
        </div>
    );
};

export default CreativeActions;
