import { MODEL_SETTINGS } from 'components/bricks/constants';
import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                title: 'Block title',
                identifier: 'peachconnect_ad-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                defaultValue: 'PeachConnect ad',
                maxLength: 150,
                dynamicValueActive: true,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Asset',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'creativeSelector',
                label: 'Select your asset',
                model: 'creatives',
                identifier: 'peachconnect_ad-creatives',
                selectors: ['upload'],
                fileType: ['video'],
                multiple: false
            }
        ]
    },
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'adType',
                label: 'Ad type',
                identifier: 'peachconnect_ad-ad_type',
                metadataType: 'peachconnectLookups',
                config: {
                    page: 0,
                    pageSize: 100,
                    lookupTypes: 'adtypes'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'regionCode',
                label: 'Country code',
                identifier: 'peachconnect_ad-region_code',
                metadataType: 'peachconnectLookups',
                config: {
                    page: 0,
                    pageSize: 120,
                    lookupTypes: 'regions'
                },
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'destination',
                label: 'Destination',
                identifier: 'peachconnect_ad-destination',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.regionCode`,
                metadataType: 'peachconnectLookups',
                config: {
                    page: 0,
                    pageSize: 120,
                    lookupTypes: 'destinations',
                    regionCode: '{{{data.settings.regionCode}}}'
                },
                useValueObject: false
            },
            {
                type: 'text',
                title: 'Reference id',
                identifier: 'peachconnect_ad-reference_id',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.regionCode`,
                model: 'reference.id',
                itemType: 'input',
                label: 'Reference id',
                maxLength: 150,
                useValueObject: false
            },
            {
                type: 'metadataInput',
                model: 'reference.type',
                label: 'Reference',
                identifier: 'peachconnect_ad-reference_type',
                condition: `additionalVars.brick.${MODEL_SETTINGS}.regionCode`,
                metadataType: 'peachconnectLookups',
                config: {
                    page: 0,
                    pageSize: 120,
                    lookupTypes: 'referencetypes',
                    regionCode: '{{{data.settings.regionCode}}}'
                },
                useValueObject: false
            }
        ]
    }
];

export default settings;
